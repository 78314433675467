<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col cols="12">
        <b-card :title="this.$route.meta.title">
          <b-alert
            v-height-fade
            dismissible
            v-model="showDismissibleAlert"
            fade
            class="mb-2"
            variant="danger"
            v-id="showDismissibleAlert == true"
          >
            <div class="alert-body mb-2">
              <span class="text-danger">{{ messages }}</span>
            </div>
          </b-alert>

          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(save)">
              <b-card-body class="p-0">
                <!-- Komponen Biaya -->
                <!-- <b-form-group label='Komponen Biaya' label-for="cost-component"> -->
                <b-form-group>
                  <label label-for="cost-component">Komponen Biaya <span class="text-danger">*</span></label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Komponen Biaya"
                    rules="required"
                  >
                    <b-form-select
                      id="cost-component"
                      v-model="model.costComponent"
                      :options="costComponentOptions"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <!-- Keterangan -->
                <!-- <b-form-group label="Keterangan" label-for="note"> -->
                <b-form-group>
                  <label label-for="note">Keterangan <span class="text-danger">*</span></label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Keterangan"
                    rules="required"
                  >
                    <b-form-input
                      id="note"
                      placeholder="Masukkan keterangan..."
                      v-model="model.note"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <!-- Biaya -->
                <!-- <b-form-group label="Biaya" label-for="cost"> -->
                <b-form-group>
                  <label label-for="cost">Biaya <span class="text-danger">*</span></label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Biaya"
                    rules="required"
                  >
                  <vue-number
                    v-model="model.cost"
                    id="cost"
                    class="form-control"
                    placeholder="Masukkan Biaya"
                    prefix= "Rp "
                    @keypress.native="NumbersOnly"
                  ></vue-number>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
                  <!-- <vue-numeric 
                    id="cost"
                    v-model="model.cost"
                    @keypress.native="numbersOnly"
                    class="form-control"
                    placeholder="Masukkan Biaya"
                    currency="Rp"
                    separator="."
                  ></vue-numeric>-->
                </b-form-group>

                <!-- Upload Kwitansi -->
                <!-- <b-form-group label="Upload Kwitansi" label-for="inputKwitansi"> -->
                <b-form-group>
                  <label label-for="inputKwitansi">Upload Kwitansi <span class="text-danger">*</span></label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Kwitansi"
                    :rules="metaAction === 'edit' ? '' : `required`"
                  >
                    <b-form-file
                      id="inputKwitansi"
                      v-model="kwitansi"
                      :placeholder="placeholderFile"
                      drop-placeholder="Taruh file disini..."
                      accept=".jpg, .png, .gif, .doc, .docx, .pdf"
                      @change="handleUploadKwitansi"
                    ></b-form-file>
                    <b-link
                      v-if="currentReceiptUrl"
                      :href="currentReceiptUrl"
                      target="_blank"
                      class="d-block text-info mt-1"
                    >
                      {{ currentReceiptUrl.split('/').at(-1) }}
                    </b-link>
                    <p class="text-gray mb-0" style="font-size: 0.857rem">Unggah file dalam format JPG, PNG, GIF, PDF, DOCX, atau DOC (batas maks. 10 MB).</p>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-card-body>
              <b-card-footer align="right" class="px-0">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  @click="goBack"
                >
                  Kembali
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  :disabled="loadingUpload"
                  class="ml-1"
                  variant="primary"
                  type="submit"
                >
                  Simpan
                </b-button>
              </b-card-footer>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormFile,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import VueNumberFormat from '@core/components/vue-number-format';

export default {
  name: 'SppdRealExpenseForm',
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormFile,
    BOverlay,
    VueNumberFormat,
  }, 
  directives: { Ripple },
  data() {
    const { sppdId, sppdPengeluaranRiilId, actionTab, beforeLink } = this.$route.params
    const { action: metaAction } = this.$route.meta

    return {
      sppdId,
      sppdPengeluaranRiilId,
      actionTab,
      beforeLink,
      metaAction,
      config: {
        api: '/real-expenses',
        redirect: `/pengeluaran-rills/${sppdId}`,
        redirectDetail: `/manajemen-sppd/${sppdId}/detail`,
      },
      model: {
        costComponent: null,
        note: '',
        cost: 0,
        receipt: '',
      },
      currentReceiptUrl: null,
      costComponentOptions: [{ value: null, text: 'Pilih Komponen Biaya' }],
      kwitansi: null,
      placeholderFile: 'Pilih file...',
      loadingUpload: false,
      messages: '',
      isLoading: false,
    }
  },
  mounted() {
    this.isLoading = true;
    if (this.metaAction === 'edit') {
      this.getRealExpense()
    }
    // console.log(this.beforeLink);
    this.getCostComponents()
  },
  methods: {
    getCostComponents() {
      axios.get('/misc/cost-components').then(res => {
        const options = res.data.data.map(({ id, name }) => ({
          value: id,
          text: name,
        }))
        this.costComponentOptions = [...this.costComponentOptions, ...options]
        this.isLoading = false;
      })
      .catch(error => {
        this.isLoading = false;
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    numbersOnly(event) {
      const evt = event || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    handleUploadKwitansi(event) {
      this.loadingUpload = true
      const file = event.target.files[0]

      if (file.size > 10 * 1024 * 1024) {
        this.$refs.resetLetter.click()
        this.letter = null
        this.model.letter = null
        this.placeholderFile = 'Pilih file disini...'
        this.$bvToast.toast(
          'Ukuran File Melebihi 10 MB, Silahkan Upload Kembali dengan ukuran file yang sesuai',
          {
            title: 'Tambah Pengeuaran Riil',
            variant: 'danger',
            solid: true,
          },
        )

        return
      }

      const formData = new FormData()
      formData.append('file', file)
      formData.append('folder', this.$route.query.folder)
      formData.append('type', 'pengeluaran riil')

      axios
        .post('/misc/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          this.loadingUpload = false
          this.model.receipt = res.data.filename
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    save() {
      const { costComponent, note, cost, receipt } = this.model

      this.isLoading = true;
      this.messages = '';
      if (this.metaAction === 'store') {
        axios
          .post(`/sppd/${this.sppdId}/real-expense`, {
            cost_component_id: costComponent,
            note,
            total_cost: cost,
            receipt,
          })
          .then(() => {
            this.isLoading = false;
            this.goBack({
              query: {
                event: 'success',
                title: 'Berhasil',
                text: 'Pengeluaran riil berhasil ditambahkan',
              },
            })
          })
          .catch(error => {
            this.isLoading = false;
            console.error(error)

            var message = "";
            if(typeof error.response.data.message === 'object'){
              message = error.response.data.message.errors[0].msg
            } else {
              message = error.response.data.message
            }
            this.makeToast('danger', "Terjadi Kesalahan", message)
          });
      } else {
        axios
          .put(`${this.config.api}/${this.sppdPengeluaranRiilId}`, {
            cost_component_id: costComponent,
            note,
            total_cost: cost,
            receipt,
          })
          .then(() => {
            this.isLoading = false;
            this.goBack({
              query: {
                event: 'success',
                title: 'Berhasil',
                text: 'Pengeluaran riil berhasil diubah',
              },
            })
          })
          .catch(error => {
            this.isLoading = false;
            console.error(error)

            var message = "";
            if(typeof error.response.data.message === 'object'){
              message = error.response.data.message.errors[0].msg
            } else {
              message = error.response.data.message
            }
            this.makeToast('danger', "Terjadi Kesalahan", message)
          });
      }
    },
    goBack({ query }) {
      if(this.beforeLink == 'detail-sppd'){
        this.$router.push({
          path: this.config.redirectDetail,
          query: { ...query, activeTab: this.actionTab },
        })
      } else {
        this.$router.push({
          path: this.config.redirect,
          query,
        })
      }
    },
    getRealExpense() {
      axios
        .get(`${this.config.api}/${this.sppdPengeluaranRiilId}/detail`)
        .then(res => {
          const { data } = res.data
          const {
            total_cost: cost,
            cost_component_id: costComponent,
            note,
            receipt,
            file,
          } = data

          this.currentReceiptUrl = file

          this.model = {
            costComponent,
            note,
            cost,
            receipt: receipt.split('/').at(-1),
          }
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style>
.kwitansi-filename {
  margin-top: 4px;
  font-size: 12px;
  font-style: italic;
}
</style>
